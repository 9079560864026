import { useEffect, useMemo, useState } from "react";
import axios from "../../axios";
import TextArea from "../textArea";
import Select from "react-select";
import TextInput from "../textInput";
import SelectInput from "../selectInput";
const CoursesFormModal = ({
  title,
  handleCloseButton,
  showAlertPopup,
  id,
  lists,
  allData,
}) => {
  const Options = [
    {
      id: 1,
      value: "low",
      label: "low",
    },
    {
      id: 2,
      value: "moderate",
      label: "moderate",
    },
    {
      id: 3,
      value: "high",
      label: "high",
    },
  ];
  // const Options = ["Low", "Moderate", "High"];
  const [steper, setsteper] = useState(1);
  const [courseName, setCourseName] = useState("");
  const [factors, setfactors] = useState([]);
  const [provider, setProvider] = useState("");
  const [duration, setDuration] = useState("");
  const [price, setPrice] = useState("");
  const [address, setAddress] = useState("");
  const [level, setLevel] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [nameValidation, setNameValidation] = useState(false);
  const [durationValidation, setDurationValidation] = useState(false);
  const [priceValidation, setPriceValidation] = useState(false);
  const [courseID, setCourseID] = useState("");
  const [value1, setvalue1] = useState([]);
  const [value2, setvalue2] = useState([]);
  const [value3, setvalue3] = useState([]);
  const [value4, setvalue4] = useState([]);
  const [value5, setvalue5] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  useMemo(() => {
    setCourseID(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setCategory(lists.categories[0].id);
    setProvider(lists.providers[0].id);
    setLevel(lists.levels[0].id);
    if (courseID) {
      handleDefaultValue();
    }

    axios
      .get("/administration/factors", {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        setfactors(res.data.data);
      });
  }, []);
  const handleDefaultValue = () => {
    let allDataArray = allData;
    let itemData = allDataArray?.find((itemID) => itemID.id === id);
    console.log(itemData);
    setCourseName(itemData.title);
    setProvider(itemData.provider_id);
    setDuration(itemData.duration);
    setPrice(itemData.price);
    setAddress(itemData.location);
    setLevel(itemData.level_id);
    setCategory(itemData.category_id);
    setDescription(itemData.description);
    setSelectedValues(itemData.factors);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = "";
    let axiosBody = {};
    courseID
      ? (url = `${axios.defaults.baseURL}/courses/${courseID}`)
      : (url = `${axios.defaults.baseURL}/courses`);

    courseID
      ? (axiosBody = {
          title: courseName,
          duration: duration,
          price: price,
          provider_id: provider,
          location: address,
          level_id: level,
          category_id: category,
          desc: description,
          _method: "put",
          // factors: JSON.stringify(selectedValues.slice(0, 5),
          factors: JSON.stringify(selectedValues),
        })
      : (axiosBody = {
          title: courseName,
          duration: duration,
          price: price,
          location: address,
          provider_id: provider,
          level_id: level,
          category_id: category,
          desc: description,
          factors: JSON.stringify(selectedValues),
          // factors: selectedValues,
        });
    await console.log("Request Body:", axiosBody);
    axios
      .post(url, axiosBody, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          handleCloseButton();
          setCourseName("");
          setProvider("");
          setDuration("");
          setPrice("");
          setLevel("");
          setAddress("");
          setCategory("");
          setDescription("");
          setSelectedValues("");
          showAlertPopup(response.data.status, response.data.message);
        } else if (response.status === 202) {
          handleCloseButton();
          setCourseName("");
          setProvider("");
          setDuration("");
          setPrice("");
          setLevel("");
          setAddress("");
          setCategory("");
          setDescription("");
          setSelectedValues("");
          showAlertPopup(response.data.status, response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          // setNameValidation(error.response.data.data.title || "");
          // setDurationValidation(error.response.data.data.duration || "");
          // setPriceValidation(error.response.data.data.price || "");
          // // console.log("server responded");
        } else if (error.request) {
          // // console.log("network error");
        } else {
          // // console.log(error);
        }
      });
  };

  return (
    <div className="modalWrapper">
      <div className="modalContainer">
        <div className="formContainer">
          <div className="sterperparent">
            <p className="welcome-text">{title}</p>
            <div className="steperchild">
              <span
                className={steper === 1 ? "activechildsteper" : null}
              ></span>
              <span
                className={steper === 2 ? "activechildsteper" : null}
              ></span>
            </div>
          </div>
          {(() => {
            switch (steper) {
              case 1:
                return (
                  <form className="form" method="post">
                    <TextInput
                      label="Course Name"
                      value={courseName}
                      placeholder="Course Name"
                      required={true}
                      validation={nameValidation}
                      onChange={(e) => {
                        setCourseName(e.target.value);
                      }}
                    />
                    <div className="parentxinputs">
                      <SelectInput
                        label="Provider"
                        value={provider}
                        options={lists.providers}
                        onChange={(e) => setProvider(e.target.value)}
                      />
                      <SelectInput
                        label="Level"
                        value={level}
                        options={lists.levels}
                        onChange={(e) => setLevel(e.target.value)}
                      />
                    </div>
                    <div className="parentxinputs">
                      <TextInput
                        label="Duration"
                        value={duration}
                        placeholder="Duration"
                        required={true}
                        validation={durationValidation}
                        onChange={(e) => setDuration(e.target.value)}
                      />
                      <TextInput
                        label="Price"
                        value={price}
                        placeholder="Price"
                        required={true}
                        validation={priceValidation}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>
                    <TextInput
                      label="Address"
                      value={address}
                      placeholder="Address"
                      required={true}
                      onChange={(e) => setAddress(e.target.value)}
                    />

                    <SelectInput
                      label="category"
                      value={category}
                      options={lists.categories}
                      onChange={(e) => setCategory(e.target.value)}
                    />
                    <TextArea
                      label="Description"
                      value={description}
                      placeholder="Description"
                      required={false}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    <div
                      className="modalFormButtons"
                      style={{ marginTop: "20px", width: "100%" }}
                    >
                      <button
                        className="secondaryButton"
                        onClick={handleCloseButton}
                      >
                        Cancel
                      </button>
                      <button
                        className="primaryButton"
                        type="submit"
                        onClick={() => {
                          setsteper(2);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </form>
                );

              case 2:
                return (
                  <div style={{ paddingTop: "20px" }}>
                    {/*Array.isArray(factors) && factors.length > 0 ? (
                      factors.map((e, i) => {
                        console.log(selectedValues)

                        return (
                          <div className="multiSelectorinputx" key={e.id}>
                            <label className="labelStyle">{e.name_en}</label>
                            <Select
                              isMulti
                              name={`selector-${i}`}
                              defaultValue={
                                // here
                              }
                              options={Options}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(selectedOptions) => {
                                const selectedResult = selectedOptions.map(
                                  (option) => option.value
                                );

                                setSelectedValues((prevState) => {
                                  const updatedValues = [...prevState];
                                  const index = updatedValues.findIndex(
                                    (item) => item.factor === e.id.toString()
                                  );

                                  if (index !== -1) {
                                    // تحديث القيم لو الفاكتور موجود
                                    updatedValues[index].result =
                                      selectedResult.join(", ");
                                  } else {
                                    // إضافة فاكتور جديد
                                    updatedValues.push({
                                      factor: e.id.toString(),
                                      result: selectedResult.join(", "),
                                    });
                                  }

                                  return updatedValues;
                                });
                              }}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <p>...loading</p>
                    )*/}
                    {Array.isArray(factors) && factors.length > 0 ? (
                      factors.map((e, i) => {
                        const convertResultToOptions = (result) => {
                          return result
                            ? result.split(", ").map((level) => {
                                // نبحث عن الكائن المناسب في Options
                                const matchedOption = Options.find(
                                  (option) => option.value === level
                                );
                                return (
                                  matchedOption || {
                                    value: level,
                                    label: level,
                                  }
                                );
                              })
                            : [];
                        };
                        // استخدام الدالة المعدلة لتحويل النتيجة
                        const getSelectedOptions = (factorId) => {
                          const factorData = selectedValues.find(
                            (item) => Number(item.factor_id) === factorId
                          );
                          if (factorData && factorData.result) {
                            return convertResultToOptions(factorData.result);
                          }
                          return [];
                        };

                        return (
                          <div className="multiSelectorinputx" key={e.id}>
                            <label className="labelStyle">{e.name_en}</label>
                            <Select
                              isMulti
                              name={`selector-${i}`}
                              value={getSelectedOptions(e.id)} // استخدام value بدل defaultValue
                              options={Options}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(selectedOptions) => {
                                console.log("/////////////////////");
                                console.log(selectedOptions);
                                console.log("/////////////////////");
                                const selectedResult = selectedOptions.map(
                                  (option) => option.value
                                );

                                setSelectedValues((prevState) => {
                                  const updatedValues = [...prevState];
                                  const index = updatedValues.findIndex(
                                    (item) => Number(item.factor_id) === e.id
                                  );

                                  if (index !== -1) {
                                    console.log("/////////////////////");
                                    console.log(selectedResult);
                                    console.log("/////////////////////");
                                    updatedValues[index].result =
                                      selectedResult.join(", ");
                                  } else {
                                    updatedValues.push({
                                      factor_id: e.id,
                                      result: selectedResult.join(", "),
                                    });
                                  }

                                  return updatedValues;
                                });
                              }}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <p>...loading</p>
                    )}

                    <div
                      className="modalFormButtons"
                      style={{ marginTop: "20px", width: "100%" }}
                    >
                      <button
                        className="secondaryButton"
                        onClick={() => {
                          setsteper(1);
                        }}
                      >
                        Back
                      </button>
                      <button
                        className="primaryButton"
                        type="submit"
                        onClick={(e) => {
                          // setsteper(2);
                          console.log(e);
                          console.log(selectedValues.slice(0, 5));
                          handleSubmit(e);
                        }}
                      >
                        {courseID ? "Update" : "Add"}
                      </button>
                    </div>
                  </div>
                );

              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default CoursesFormModal;

// onClick={handleSubmit}

// {courseID ? "Update" : "Add"}

// {Array.isArray(factors) && factors.length > 0 ? (
//   factors.map((e, i) => {
//     // دالة تحويل النص لمصفوفة من الأوبجيكتات
//     const convertResultToOptions = (result) => {
//       return result
//         ? result.split(", ").map((level, index) => ({
//             id: index + 1,
//             value: level,
//             label: level,
//           }))
//         : [];
//     };

//     // دالة للحصول على القيم المختارة بناءً على e.id
//     const getSelectedOptions = (factorId) => {
//       // هنا بنفترض إن الـ factor_id في selectedValues بتكون رقم أو ممكن نحولهم للرقم
//       const factorData = selectedValues.find(
//         (item) => Number(item.factor_id) === factorId
//       );
//       if (factorData && factorData.result) {
//         return convertResultToOptions(factorData.result);
//       }
//       return [];
//     };

//     return (
//       <div className="multiSelectorinputx" key={e.id}>
//         <label className="labelStyle">{e.name_en}</label>
//         <Select
//           isMulti
//           name={`selector-${i}`}
//           // استخدام value بدل defaultValue لضمان التحكم في القيمة
//           value={getSelectedOptions(e.id)}
//           options={Options}
//           className="basic-multi-select"
//           classNamePrefix="select"
//           onChange={(selectedOptions) => {
//             const selectedResult = selectedOptions.map(
//               (option) => option.value
//             );

//             setSelectedValues((prevState) => {
//               const updatedValues = [...prevState];
//               // التأكد من المقارنة بين أرقام (أو تحويلهم لرقم)
//               const index = updatedValues.findIndex(
//                 (item) => Number(item.factor_id) === e.id
//               );

//               if (index !== -1) {
//                 // تحديث القيم لو الفاكتور موجود
//                 updatedValues[index].result =
//                   selectedResult.join(", ");
//               } else {
//                 // إضافة فاكتور جديد
//                 updatedValues.push({
//                   factor_id: e.id,
//                   result: selectedResult.join(", "),
//                 });
//               }

//               return updatedValues;
//             });
//           }}
//         />
//       </div>
//     );
//   })
// ) : (
//   <p>...loading</p>
// )}
